import React from 'react';

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="container-fluid">

                    <div id="logo" className="pull-left">
                        <a href="#intro"><img src={process.env.PUBLIC_URL + 'logo.png'} alt="" title="" /></a>
                    </div>

                    <nav id="nav-menu-container">
                        <ul className="nav-menu">
                            <li className="menu-active"><a href="#intro">Home</a></li>
                            {/* <li><a href="https://blog.kaissa.gg">Blog</a></li> */}
                            {/* <li><a href="#products">Produtos</a></li> */}
                            <li><a href="#team">Kaissa</a></li>
                            <li><a href="#clients">Clientes</a></li>
                            <li><a href="#partners">Parceiros</a></li>
                            <li><a href="#contact">Contato</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }
}

export default Header;
