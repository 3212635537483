import React from 'react';

class Intro extends React.Component {
    render() {
        return (
            <section id="intro">
                <div className="intro-container">
                    <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

                        <ol className="carousel-indicators"></ol>

                        <div className="carousel-inner" role="listbox">

                            <div className="carousel-item active" style={{ 'backgroundImage': `url(${process.env.PUBLIC_URL + 'img/intro/slide-consultoria.jpg'})` }}>
                                <div className="carousel-container">
                                    <div className="carousel-content">
                                        <h2>Consultoria e Serviços</h2>
                                        <p>🛠️ Planejamento e Gestão de Equipes</p>
                                        <p>📊 Coleta e Análise de Dados</p>
                                        <p>🎯 Agenciamento de Carreira</p>
                                        <p>🧩 Recrutamento e Seleção</p>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" style={{ 'backgroundImage': `url(${process.env.PUBLIC_URL + 'img/intro/slide-midiasdigitais.jpg'})` }}>
                                <div className="carousel-container">
                                    <div className="carousel-content">
                                        <h2>Comunicação Estratégica</h2>
                                        <p>📋 Branding e Planejamento Estratégico</p>
                                        <p>📝 Produção de conteúdo </p>
                                        <p>📲 Redes Sociais</p>
                                        <p>💡 Design e Identidade visual</p>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" style={{ 'backgroundImage': `url(${process.env.PUBLIC_URL + 'img/intro/slide-medico.jpg'})` }}>
                                <div className="carousel-container">
                                    <div className="carousel-content">
                                        <h2>Medicina Esportiva</h2>
                                        <p>🧠 Psicologia Esportiva</p>
                                        <p>💪 Fisioterapia e Funcional</p>
                                        <p>🍽️ Nutrição e Saúde </p>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" style={{ 'backgroundImage': `url(${process.env.PUBLIC_URL + 'img/intro/slide-juridico.jpg'})` }}>
                                <div className="carousel-container">
                                    <div className="carousel-content">
                                        <h2>Assessoria Jurídica</h2>
                                        <p>⚖️ Regularização e legalidade</p>
                                        <p>🧾 Registro de Imagem</p>
                                        <p>🤝 Contratação de players e staff</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="carousel-item" style={{ 'backgroundImage': `url(${process.env.PUBLIC_URL + 'img/intro/slide-campeonato.jpg'})` }}>
                                <div className="carousel-container">
                                    <div className="carousel-content">
                                        <h2>Apoio ao Cenário</h2>
                                        <p>🏆 Promoção de Campeonatos</p>
                                        <p>💰 Patrocínios de Organizações e Competições</p>
                                        <p>⚙️ Organização de Eventos</p>
                                    </div>
                                </div>
                            </div>                                                   
                        </div>

                        <a className="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon ion-chevron-left" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>

                        <a className="carousel-control-next" href="#introCarousel" role="button" data-slide="next">
                            <span className="carousel-control-next-icon ion-chevron-right" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>

                    </div>
                </div>
            </section>
        )
    }
}

export default Intro;
