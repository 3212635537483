import React from 'react'
import $ from 'jquery'
import emailjs from 'emailjs-com'

class Contact extends React.Component {
    constructor(props) {
        super(props);

        emailjs.init(process.env.REACT_APP_EMAILJS_TOKEN);
        
        this.state = { 
            subject: '', 
            message: '', 
            name: '', 
            email: '' 
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleSubmit() {
        const templateId = 'template_cnLpUB3U';

        this.sendMessage(templateId, { subject: this.state.subject, message_html: this.state.message, from_name: this.state.name, reply_to: this.state.email })
    }

    sendMessage(templateId, variables) {
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, templateId,
            variables
        ).then(res => {
            $("#sendmessage").addClass("show");
            $("#errormessage").removeClass("show");
            $('.contactForm').find("input, textarea").val("");
        })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => {
                $("#sendmessage").removeClass("show");
                $("#errormessage").addClass("show");
                $('#errormessage').html('There was a problem sending your message. Please contact us at contact@kaissa.gg. Error code: ' + err.status);
            })
    }

    componentDidMount() {
        //Contact
        $('form.contactForm').submit(function () {
            var f = $(this).find('.form-group'),
                ferror = false,
                emailExp = /^[^\s()<>@,;:/]+@\w[\w.-]+.[a-z]{2,}$/i;

            f.children('input').each(function () { // run all inputs

                var i = $(this); // current input
                var rule = i.attr('data-rule');

                if (rule !== undefined) {
                    var ierror = false; // error flag for current input
                    var pos = rule.indexOf(':', 0);
                    if (pos >= 0) {
                        var exp = rule.substr(pos + 1, rule.length);
                        rule = rule.substr(0, pos);
                    } else {
                        rule = rule.substr(pos + 1, rule.length);
                    }

                    switch (rule) {
                        default:
                        case 'required':
                            if (i.val() === '') {
                                ferror = ierror = true;
                            }
                            break;

                        case 'minlen':
                            if (i.val().length < parseInt(exp)) {
                                ferror = ierror = true;
                            }
                            break;

                        case 'email':
                            if (!emailExp.test(i.val())) {
                                ferror = ierror = true;
                            }
                            break;

                        case 'checked':
                            if (!i.attr('checked')) {
                                ferror = ierror = true;
                            }
                            break;

                        case 'regexp':
                            exp = new RegExp(exp);
                            if (!exp.test(i.val())) {
                                ferror = ierror = true;
                            }
                            break;
                    }
                    i.next('.validation').html((ierror ? (i.attr('data-msg') !== undefined ? i.attr('data-msg') : 'wrong Input') : '')).show('blind');
                }
            });
            f.children('textarea').each(function () { // run all inputs

                var i = $(this); // current input
                var rule = i.attr('data-rule');

                if (rule !== undefined) {
                    var ierror = false; // error flag for current input
                    var pos = rule.indexOf(':', 0);
                    if (pos >= 0) {
                        var exp = rule.substr(pos + 1, rule.length);
                        rule = rule.substr(0, pos);
                    } else {
                        rule = rule.substr(pos + 1, rule.length);
                    }

                    switch (rule) {
                        default:
                        case 'required':
                            if (i.val() === '') {
                                ferror = ierror = true;
                            }
                            break;

                        case 'minlen':
                            if (i.val().length < parseInt(exp)) {
                                ferror = ierror = true;
                            }
                            break;
                    }
                    i.next('.validation').html((ierror ? (i.attr('data-msg') !== undefined ? i.attr('data-msg') : 'wrong Input') : '')).show('blind');
                }
            });
            if (ferror) return false;

            return false;
        });
    }

    render() {
        return (
            <section id="contact" className="section-bg wow fadeInUp" style={{ "visibility": "visible", "animationName": "fadeInUp" }}>
                <div className="container">

                    <div className="section-header">
                        <h3>Entre em Contato</h3>
                        <p>Escreva-nos, gostaríamos de ouvir o que você tem a nos falar!</p>
                    </div>

                    <div className="row contact-info">

                        <div className="col-md-6">
                            <div className="contact-phone">
                                <i className="fab fa-whatsapp"></i>                                
                                <h3>Whatsapp</h3>
                                {/* <p><a href="tel:+5511950574681">(11) 95057-4681</a></p> */}
                                <p>(11) 95057-4681</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="contact-email">
                                <i className="fas fa-envelope"></i>
                                <h3>Email</h3>
                                <p><a href="mailto:contact@kaissa.gg">CONTACT@KAISSA.GG</a></p>
                            </div>
                        </div>

                    </div>

                    <div className="form">
                        {/* <div id="sendmessage">Your message has been sent. Thank you!</div> */}
                        <div id="sendmessage">Sua mensagem foi enviada. Muito Obrigado!</div>
                        <div id="errormessage"></div>
                        <form className="contactForm">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    {/* <input type="text" onChange={this.handleChange} value={this.state.name} name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" /> */}
                                    <input type="text" onChange={this.handleChange} value={this.state.name} name="name" className="form-control" id="name" placeholder="Seu Nome" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                    <div className="validation"></div>
                                </div>
                                <div className="form-group col-md-6">
                                    {/* <input type="email" onChange={this.handleChange} value={this.state.email} className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" /> */}
                                    <input type="email" onChange={this.handleChange} value={this.state.email} className="form-control" name="email" id="email" placeholder="Seu Email" data-rule="email" data-msg="Please enter a valid email" />
                                    <div className="validation"></div>
                                </div>
                            </div>

                            <div className="form-group">
                                {/* <input type="text" onChange={this.handleChange} value={this.state.subject} className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" /> */}
                                <input type="text" onChange={this.handleChange} value={this.state.subject} className="form-control" name="subject" id="subject" placeholder="Assunto Email" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group">
                            {/* <textarea onChange={this.handleChange} value={this.state.message} className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea> */}
                                <textarea onChange={this.handleChange} value={this.state.message} className="form-control" name="message" rows="5" data-rule="required" data-msg="Por favor, escreva algo para nós" placeholder="Mensagem"></textarea>
                                <div className="validation"></div>
                            </div>
                            {/* <div className="text-center"><button type="submit" onClick={this.handleSubmit}>Send Message</button></div> */}
                            <div className="text-center"><button type="submit" onClick={this.handleSubmit}>Enviar Mensagem</button></div>
                        </form>
                    </div>

                </div>
            </section>
        )
    }
}

export default Contact;
