import React from 'react';

class Team extends React.Component {
    render() {
        return (
            <section id="team">
                <div className="container">
                    <div className="section-header wow fadeInUp" style={{"visibility": "visible", "animationName": "fadeInUp"}}>
                        <h3>Time Kaissa</h3>
                        <p>O Coração, a Mente e a Alma da Agência!</p>
                    </div>

                    <div className="row">
                         
                        {/* <div className="col-lg-4 col-md-6 wow fadeInUp" style={{"visibility": "invisible", "animationName": "fadeInUp"}}>
                            <div className="member">
                                <img src={process.env.PUBLIC_URL + 'img/nathan_charles.jpg'} className="rounded-circle" alt="Cinque Terre" />
                                <div className="member-info">
                                    <div className="member-info-content">
                                        <h4>Nathan Charles</h4>
                                        <span>Software Developer and Big Data engineer</span>
                                        <div className="social">
                                            <a href="https://www.facebook.com/nathan.charless" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a>
                                            <a href="https://www.linkedin.com/in/nathan-santos-94393a180/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{"visibility": "invisible", "animationDelay": "0.1s", "animationName": "fadeInUp"}}>
                            <div className="member">
                                <img src={process.env.PUBLIC_URL + 'img/victor_anjos.jpg'} className="rounded-circle" alt="Cinque Terre" />
                                <div className="member-info">
                                    <div className="member-info-content">
                                        <h4>Victor Anjos</h4>
                                        <span>Product Owner and Project Manager</span>
                                        <div className="social">
                                            <a href="https://www.facebook.com/victorhugo.anjos.7" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a>
                                            <a href="https://www.linkedin.com/in/victor-anjos-1730932a/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{"visibility": "visible", "animationDelay": "0.3s", "animationName": "fadeInUp"}}>
                            <div className="member">
                                <img src={process.env.PUBLIC_URL + 'img/victor_viola.jpg'} className="rounded-circle" alt="Cinque Terre" />
                                <div className="member-info">
                                    <div className="member-info-content">
                                        <h4>Victor Viola</h4>
                                        <span>Software Developer and Data Scientist</span>
                                        <div className="social">
                                            <a href="https://www.facebook.com/homemsancto" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a>
                                            <a href="https://www.linkedin.com/in/victorvsanchez/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s" style={{"visibility": "visible", "animationDelay": "0.2s", "animationName": "fadeInUp"}}>
                            <div className="member">
                                <img src={process.env.PUBLIC_URL + 'img/team/alexandre_galvao.jpg'} className="rounded-circle" alt="Alexandre_CEO" />
                                <div className="member-info">
                                    <div className="member-info-content">
                                        <h4>Alexandre "Galvão"</h4>
                                        <span>Co-Founder - Gestão, Vendas e Conteúdo</span>
                                        <div className="social">
                                            {/* <a href="https://www.facebook.com/alexandre.agfernandes" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a> */}
                                            <a href="mailto:alexandre.galvao@kaissa.gg" target="_blank" rel="noopener noreferrer">
                                                <i className="fas fa-envelope"></i></a>
                                            {/* <a href="https://instagram.com/alexgalvaof/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a> */}
                                            <a href="https://twitter.com/AlexGalvaoF" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                            <a href="https://linkedin.com/in/alexandregalvaof/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin-in"></i></a>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s" style={{"visibility": "visible", "animationDelay": "0.2s", "animationName": "fadeInUp"}}>
                            <div className="member">
                                <img src={process.env.PUBLIC_URL + 'img/team/matheus_pinheiro.jpg'} className="rounded-circle" alt="Matheus_CTO"/>
                                <div className="member-info">
                                    <div className="member-info-content">
                                        <h4>Matheus "FairFruit"</h4>
                                        <span>Co-Founder - Pesquisa e Desenvolvimento</span>
                                        {/* <span>Technical leader and Big Data Engineer</span> */}
                                        <div className="social">
                                            {/* <a href="https://www.facebook.com/matheus.each" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a> */}
                                            <a href="mailto:matheus.pinheiro@kaissa.gg" target="_blank" rel="noopener noreferrer">
                                                <i className="fas fa-envelope"></i></a>
                                            <a href="https://linkedin.com/in/mp-pinheiro/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.8s" style={{"visibility": "visible", "animationDelay": "0.2s", "animationName": "fadeInUp"}}>
                            <div className="member">
                                <img src={process.env.PUBLIC_URL + 'img/team/matheus_gomes.jpg'} className="rounded-circle" alt="Cinque Terre" />
                                <div className="member-info">
                                    <div className="member-info-content">
                                        <h4>Matheus "Amadeu" Gomes</h4>
                                        <span>Co-Founder - Marketing e Mídias Digitais</span>
                                        <div className="social">
                                            {/* <a href="https://www.facebook.com/matheus.gomes.127" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a> */}
                                            <a href="mailto:matheus.gomes@kaissa.gg" target="_blank" rel="noopener noreferrer">
                                                <i className="fas fa-envelope"></i></a>
                                            <a href="https://www.linkedin.com/in/matheus-gomes-9a92bb196/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Team;
