import React from 'react';

class Partners extends React.Component {
    render() {
        return (
            <section id="partners">
                <div className="container">
                    <div className="section-header wow fadeInUp" style={{"visibility": "visible", "animationName": "fadeInDown"}}>
                        <h3>Parceiros</h3>
                        <p>Aqueles que juntos da Kaissa trazem excelência para o cenário</p>
                    </div>

                    <div className="row">

                        <div className="col-4 col-md-3 wow fadeInUp" data-wow-delay="0.2s" style={{"visibility": "visible", "animationDelay": "0.3s", "animationName": "None"}}>
                            <div className="partner">
                                <img src={process.env.PUBLIC_URL + 'img/partner/Psicologia_Gamer.png'} className="partner-photo" alt="Vital Advogados" />
                                <div className="partner-info">
                                    <div className="partner-info-content">
                                        <h4>Psicologia Gamer</h4>
                                        <span>Preparação de Equipes, Atendimento Clínico e Consultoria</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/psicologiagamer/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://apptuts.bio/psicologiagamer" target="_blank" rel="noopener noreferrer">
                                                <i class="fas fa-globe-americas"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-4 col-md-3 wow fadeInUp" data-wow-delay="0.4s" style={{"visibility": "visible", "animationDelay": "0.3s", "animationName": "None"}}>
                            <div className="partner">
                                <img src={process.env.PUBLIC_URL + 'img/partner/Vital_Advogados.jpeg'} className="partner-photo" alt="Vital Advogados" />
                                <div className="partner-info">
                                    <div className="partner-info-content">
                                        <h4>Vital Sociedade de Advogados</h4>
                                        <span>Advocacia Poliesportiva e Digital</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/vitaladvocacia_/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/Sociedadevital" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                            <a href="https://www.facebook.com/VitalSociedadeAdv" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a>
                                            <a href="https://www.linkedin.com/in/felipe-bezerril-245642163/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 col-md-3 wow fadeInUp" data-wow-delay="0.6s" style={{"visibility": "visible", "animationDelay": "0.3s", "animationName": "None"}}>
                            <div className="partner">
                                <img src={process.env.PUBLIC_URL + 'img/partner/Marina_Pagliari.JPG'} className="partner-photo" alt="Marina Pagliari" />
                                <div className="partner-info">
                                    <div className="partner-info-content">
                                        <h4>Marina Pagliari</h4>
                                        <span>Marketing, Estratégia, Redação e Relações Públicas</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/mariinapagliari/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/mariinapagliari" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                            <a href="https://www.linkedin.com/in/marinapagliari/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-linkedin-in"></i></a>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-4 col-md-3 wow fadeInUp" data-wow-delay="0.8s" style={{"visibility": "visible", "animationDelay": "0.3s", "animationName": "None"}}>
                            <div className="partner">
                                <img src={process.env.PUBLIC_URL + 'img/partner/ES_Mind.png'} className="partner-img" alt="ESMind" />
                                <div className="partner-info">
                                    <div className="partner-info-content">
                                        <h4>eS MIND</h4>
                                        <span>Palestras, Atendimentos Individuais</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/esmindpsicologia/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Partners;
