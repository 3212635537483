import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 footer-info">
                                <h3>Kaissa Agência de eSports</h3>
                                <p>Nosso foco é conectar pessoas 🌎 para crescermos juntos dentro do cenário.</p>
                                <a href="https://linktr.ee/KaissaGGeSportsAgency" target="_blank" rel="noopener noreferrer" className="blog">linktr.ee/KaissaGGeSportsAgency</a>
                                {/* <p>Connecting people to the world of e-sports.</p> */}
                            </div>

                            <div className="col-lg-6 col-md-6 footer-contact">
                                <h4>Entre em Contato</h4>
                                {/* <h4>Contact Us</h4> */}
                                <p>
                                    {/* Our social media is still in early stages, but feel free to shoot with an email or a message! <br/> */}
                                    Nos siga nas redes socias e entre e venha falar conosco por Email, WhatsApp ou pode DM mesmo!<br/>
                                    {/* <strong>Email:</strong> contact@kaissa.gg<br/> */}
                                </p>

                                <div className="social-links">
                                <a href="https://blog.kaissa.gg" target="_blank" rel="noopener noreferrer" className="blog"><i className="fa fa-bold"></i></a>
                                <a href="https://twitter.com/Kaissa_GG" target="_blank" rel="noopener noreferrer" className="twitter"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/kaissa_gg/" target="_blank" rel="noopener noreferrer" className="instagram"><i className="fab fa-instagram"></i></a>
                                <a href="https://www.facebook.com/Kaissagg-113633116751368/" target="_blank" rel="noopener noreferrer" className="facebook"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://www.linkedin.com/company/52148230" target="_blank" rel="noopener noreferrer" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>

                            </div>
                        </div>
                    </div>

                    <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong>Kaissa</strong> 2019. All Rights Reserved
                    </div>
                    <div className="credits">
                        <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
