import React from 'react';

class Clients extends React.Component {
    render() {
        return (
            <section id="clients">
                <div className="container">
                    <div className="section-header wow fadeInDown" style={{"visibility": "visible", "animationName": "fadeInDown"}}>
                        <h3>Clientes</h3>
                        <p>Todos os que confiam nos serviços e produtos Kaissa!</p>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="0.4s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Griffos.png'} className="client-photo" alt="Griffos Logo" />
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Griffos e-Sports</h4>
                                        <span>Valorant</span>
                                        <span>Rocket League</span>
                                        <span>Fórmula 1</span>
                                        <span>Rainbow Six</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/griffos_gg/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/griffos_gg" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="0.8s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Rotes.png'} className="client-photo-small" alt="Rotes Logo"/>
                                  <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Rotes e-Sports</h4>
                                        <span>League of Legends</span>
                                        <div className="social">
                                            <a href="https://twitter.com/rotesesports" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="1.2s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Moon_Tigers.png'} className="client-photo" alt="MoonTigers Logo"/>
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Moon Tigers e-Sports</h4>
                                        <span>League of Legends</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/_moontigers/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/_MoonTigers" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="0.5s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Nightfall_Wolves.png'} className="client-photo" alt="Nightfall Wolves Logo" />
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Nightfall Wolves e-Sports</h4>
                                        <span>League of Legends</span>
                                        <div className="social">
                                            <a href="https://twitter.com/NightfallWolves" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="0.9s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Gamer_Squad.png'} className="client-photo" alt="GamerSquad Logo"/>
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Gamer Squad e-Sports</h4>
                                        <span>League of Legends</span>
                                        <span>Team Fight Tactics</span>
                                        <span>Wild Rift</span>
                                        <span>Valorant</span>
                                        <div className="social">
                                            <a href="https://www.facebook.com/GamerSquadEsports" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i></a>
                                            <a href="https://www.instagram.com/gamersquadesports/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/GamerSquadBRL" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="1.3s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Nefarious.png'} className="client-photo" alt="Nefarious Logo" />
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Nefarious e-Sports</h4>
                                        <span>League of Legends</span>
                                        <span>Rocket League</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/nefarious_gg/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/gg_nefa" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="0.6s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Royal_Guards.png'} className="client-photo" alt="Royal Guards Logo"/>
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Royal Guards e-Sports</h4>
                                        <span>League of Legends</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/royalguardsesports/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="1.0s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/Nuklear.png'} className="client-photo" alt="Nuklear Logo" />
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>Nuklear e-Sports</h4>
                                        <span>League of Legends</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/nuklearesports/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>
                                            <a href="https://twitter.com/NuklearEsports" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-delay="1.4s" style={{"visibility": "visible", "animationDelay": "0.0s", "animationName": "slideInRight"}}>
                            <div className="client">
                                <img src={process.env.PUBLIC_URL + 'img/client/11Birds.png'} className="client-photo-small" alt="11Birds Logo" />
                                <div className="client-info">
                                    <div className="client-info-content">
                                        <h4>11Birds e-Sports</h4>
                                        <span>League of Legends</span>
                                        <div className="social">
                                            <a href="https://www.instagram.com/11birds.esports/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram"></i></a>                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        )
    }
}

export default Clients;