import React from 'react';
import Intro from './Intro';
import Clients from './Clients';
import Partners from './Partners';
import Team from './Team';
import Contact from './Contact';

class Body extends React.Component {
    render() {
        return (
            <div className="Body">
                <Intro/>
                <Team/>
                <Clients/>
                <Partners/>
                <Contact/>
            </div>
        )
    }
}

export default Body;
